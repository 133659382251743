
























import { defineComponent } from '@vue/composition-api'
import { Button, InputField, Form } from '@sennder/plankton'
import SearchOrdersForm from '@/components/organisms/SearchOrdersForm/SearchOrdersForm.vue'
import TextLink from '@/components/atoms/TextLink/TextLink.vue'

export default defineComponent({
  name: 'NoMatchingOrders',
  components: {
    Button,
    InputField,
    Form,
    SearchOrdersForm,
    TextLink
  }
})
