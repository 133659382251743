
































import { defineComponent, inject, ref, Ref } from '@vue/composition-api'
import { Button, InputField, Form } from '@sennder/plankton'

import useOrderDetails from '@/hooks/useOrderDetailRedirect'
import events from '@/notifications/events'
import AnalyticsService, {
  AnalyticsServiceToken
} from '@/services/Analytics/AnalyticsService'

export default defineComponent({
  name: 'SearchOrdersForm',
  components: { Form, InputField, Button },
  props: {
    showNoResults: { type: Boolean, default: false },
    searchAndRedirect: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    title: { type: String, default: null }
  },
  setup(props, { root: { $router } }) {
    const analytics = inject<AnalyticsService>(AnalyticsServiceToken)

    const reference: Ref<string | null> = ref(null)
    const isLoading: Ref<boolean> = ref(false)

    const { computeReferences, noResults } = useOrderDetails($router)

    const handleSubmit = async () => {
      if (reference.value) {
        analytics?.trackEvent(events.SEARCH_MANUAL_SUBMIT, {
          input: reference.value
        })
        isLoading.value = true

        try {
          await computeReferences([reference.value], props.searchAndRedirect)
        } finally {
          isLoading.value = false
        }
      }
    }

    return { noResults, handleSubmit, reference, isLoading }
  }
})
