








import { defineComponent } from '@vue/composition-api'
import NoMatchingOrders from '@/components/organisms/NoMatchingOrders/NoMatchingOrders.vue'

export default defineComponent({
  name: 'SearchOrders',
  components: {
    NoMatchingOrders
  }
})
